import {
  defineRule,
  configure
} from 'vee-validate'

import {
  email,
  confirmed
} from '@vee-validate/rules'

import { localize } from '@vee-validate/i18n'
import { parsePhoneNumber } from 'awesome-phonenumber'

// ---VENDOR
defineRule('email', email)
defineRule('confirmed', confirmed)

// ---CUSTOM
defineRule('req', value => {
  if (!value || !value.length) {
    return 'Questo campo è obbligatorio.'
  }
  return true
})

defineRule('phone', value => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true
  }

  let trimmed = value.replace(/\s/g, '')
  trimmed = trimmed.replace('+', '')
  // Check if phone number
  if (isNaN(trimmed)) {
    return 'Numero di telefono non valido (esempio corretto: +39XXXXXXXXXX)'
  }
  return true
})

defineRule('customEmail', value => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true
  }

  if (value.includes('@promotica.it') || value.includes('@vivapremiaconsulting.it')) {
    return true
  }

  return 'Dominio non valido.'
})

defineRule('phoneNumber', value => {
  // Field is empty, should pass
  const isPhone = parsePhoneNumber(value)

  // console.log('PHONE', isPhone)
  if (isPhone.valid === true) {
    return true
  }

  return 'Numero di telefono non valido.'
})

// ---TRANSLATIONS
configure({
  // Generates an Italian message locale generator
  generateMessage: localize('it', {
    messages: {
      email: 'Indirizzo non valido',
      confirmed: 'I due campi non coincidono'
    }
  })
})
